$jenkins-modal-padding: 1.5rem;

.jenkins-modal {
  border-radius: 0.4rem;
  border: none;
  background: transparent;
  box-shadow: var(--modal-box-shadow);
  backdrop-filter: var(--modal-backdrop-filter);
  animation: jenkins-modal-animate-in 0.25s cubic-bezier(0, 0.68, 0.5, 1.5);
  overflow: hidden;
  padding: 0;

  &::backdrop {
    background: var(--modal-backdrop-background);
    animation: jenkins-modal-backdrop-animate-in 0.15s;
  }

  &__contents {
    overflow: auto;
    padding: 0.5rem 1.5rem 1.5rem;
    max-height: 75vh;
  }

  &__title {
    font-size: 1.1rem;
    font-weight: 600;
    padding: $jenkins-modal-padding;
    margin: 0;
  }

  &__subtitle {
    font-size: 1rem;
    font-weight: 600;
    color: var(--text-color-secondary);
    padding: 0;
    margin: 0 0 1.5rem;
  }

  &__close-button {
    position: absolute;
    top: $jenkins-modal-padding - 0.5rem;
    right: $jenkins-modal-padding - 0.5rem;
    aspect-ratio: 1;
    padding: 0;

    &::before,
    &::after {
      border-radius: 100%;
      outline: none;
    }

    &::after {
      backdrop-filter: contrast(0.9) blur(5px);
    }
  }
}

.jenkins-modal--hidden {
  animation: jenkins-modal-animate-out 0.1s linear;

  &::backdrop {
    animation: jenkins-modal-backdrop-animate-out 0.2s ease;
  }
}

@keyframes jenkins-modal-backdrop-animate-in {
  from {
    opacity: 0;
  }
}

@keyframes jenkins-modal-animate-in {
  from {
    scale: 85%;
    opacity: 0;
  }
}

@keyframes jenkins-modal-backdrop-animate-out {
  to {
    opacity: 0;
  }
}

@keyframes jenkins-modal-animate-out {
  to {
    scale: 85%;
    opacity: 0;
  }
}
