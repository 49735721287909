@use "../abstracts/mixins";

.content-block {
  border-radius: 4px;
  border: 2px solid var(--light-grey);
}

.content-block__body {
  padding: 1.5rem;
}

.content-block__body > *:first-child {
  margin-top: 0;
}

.content-block__body > *:last-child {
  margin-bottom: 0;
}

.content-block__footer {
  background-color: var(--light-grey);
  padding: 0.5rem 1.5rem;
}

.content-block__link-list {
  padding: 0;
}

.content-block__link-list li {
  list-style: none;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 0.75rem;
  }
}

.content-block__link {
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  background-color: var(--light-grey);

  @include mixins.link-dark;

  &:focus {
    outline-color: var(--focus);
  }

  .trailing-icon .svg-icon {
    &.icon-sm {
      height: 1.25rem;
      width: 1.25rem;
    }
  }
}

.content-block__help-link {
  background-color: var(--white);
}

// Empty state specific blocks

.empty-state-block {
  max-width: 600px;
  margin: 0 auto;
  margin-top: 1.5rem;
}

.empty-state-section {
  margin-top: 2rem;
}

.empty-state-section-list {
  padding: 0;

  li {
    list-style: none;
    margin-bottom: 1rem;
  }
}
